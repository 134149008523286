import "./inicio.scss"
import React, { Component } from 'react'
import Formulario from '../../components/Formulario/formulario'
import OwlCarousel from "react-owl-carousel2";
import AcordeonInicio from "../../components/acordeon/acordeon"
import MediaQuery from 'react-responsive';
import sr from '../../components/scrollreveal/scrollReveal'
import ModalSuccess from '../../components/modalSuccessForm/modalSuccess'
import $ from 'jquery'
import Modal from '../../components/modal/modal'


import {options,optionsAtributos,events,
        optionsInicio, eventsInicio, configModal, afterbotton, afterbotton2,
        titleleft, settings2, settings1,optionDorm,groupedOptions} from './variables'
import Calculadora from '../../components/calculadora/calculadora';
import sliderIcons from '../../components/sliderIcons/sliderIcons';
import SliderIcons from '../../components/sliderIcons/sliderIcons';
import Stage from '../../components/stages/stages';
import Aliados from '../../components/aliados/aliados';


export default class Inicio extends Component {
    constructor(props){
        super(props)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.onPlayerStateChange = this.onPlayerStateChange.bind(this)
        this.onPlayerReady = this.onPlayerReady.bind(this)
        this.play = this.play.bind(this)
        this.state = {
            autoplay:false,
            video:"",
        }
    }
    componentDidMount(){
        setInterval(() => {
            $('.Galery .MyCarouselHorizontal').lightGallery(configModal);
            $('.cont-gallery.ubicacion').lightGallery(configModal);
            $('.cont-gallery.ubicacion').lightGallery(configModal);
            $('.MyCarouselHorizontal').lightGallery({
                selector:".slide-video",
                thumbnail:true,animateThumb: false,showThumbByDefault: false,subHtmlSelectorRelative: true,rotate:false,actualSize:false,download:true,pinterest:false,googlePlus:false,twitter:true,enableDrag:true,
            });
        }, 1000);
        
        sr.reveal(".title-left", titleleft);
        sr.reveal(".title-right", titleleft);
        console.log(".")
        this.init();

        window.onYouTubeIframeAPIReady = e => {
            this.YT = window.YT;
            console.log("CHECK:",this.YT)
            this.individualsPlayer = new window.YT.Player("player",{
                with:'100%',
                height:'100%',
                videoId: 'W_NnSClLMhs',
                playerVars: { 'autoplay': 0, 'playsinline': 3 },
                events:{
                    onReady: this.onPlayerReady,
                    onStateChange: this.onPlayerStateChange
                }
            })
        };
    }
    init() {
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
    onPlayerReady(event){
        this.setState({video:event})
        console.log(event)
        // event.target.stop();
    }
    onPlayerStateChange(event){
        console.log("onChage: ",event)
        if(event.data == -1){
            this.slider.slickPause()
        }
        if(event.data == 0){
            this.state.video.target.stopVideo()
            this.slider.slickNext()
            this.slider.slickPlay()
        }
    }
    play(current,next){
        console.log(next)
        if(this.state.vide){
            if(next != 0){
                this.state.video.target.stopVideo()
            }
        }
    }

    changeGallery(e,element){
        $(".cont-gallery").removeClass("active")
        $(`.${e}`).addClass("active")
    }
    handleInputChange(newValue){
        this.setState({dorms:newValue.value})
    }
    render() {
        return (
            <div className="Inicio">
                <Modal>
                    <div className="content-img-error">
                        <div className="button-close" data-type="modal">Aceptar</div>
                        <img src={require("../../Assets/images/inicio/slider/modal-error.png")}></img>
                    </div>
                </Modal>
                <section className="content-inicio container mx-auto">
                    <img className="b-1" src={require("../../Assets/images/iconos/background-1.png")}></img>
                    <img className="point-1" src={require("../../Assets/images/iconos/points-1.png")}></img>
                    {/* <div style={{display:"none"}} id="video1">
                        <video class="lg-video-object lg-html5 video-js vjs-default-skin" controls preload="none">
                            <source src={require("../../Assets/video/Actualización video del proyecto - 8732.mp4")} type="video/mp4"/>
                            Your browser does not support HTML5 video.
                        </video>
                    </div> */}
                    <div className="slider-inicio">
                    <OwlCarousel ref="car" options={options} events={events} className="MyCarouselHorizontal">
                        
                        {/* <div className="slide slide-video" data-poster="video-poster1.jpg" data-sub-html="video caption1" data-html="#video1">
                            <div className='btn-youtube'>
                                <img src={require("../../Assets/images/iconos/btn-youtube.svg").default}></img>
                            </div>
                            <img className="img" src={require("../../Assets/images/inicio/slider/avance -de-obra.PNG")} 
                            ></img>
                        </div> */}
                       
                        {/* <div className="slide">
                            <img className="img" src={require("../../Assets/images/inicio/slider/portada-febrero-2023.png")}></img>
                        </div> */}
                        <div className="slide">
                            <img className="img" src={require("../../Assets/images/inicio/slider/espacio-ideal.png")}></img>
                        </div>
                        <div className="slide">
                            <img className="img" src={require("../../Assets/images/inicio/slider/mapa_metropolitano.png")}></img>
                        </div>
                        
                    </OwlCarousel>
                    </div>
                    <div className="formulario-inicio">
                        <Formulario></Formulario>
                    </div>
                </section>
                <ModalSuccess></ModalSuccess>
                <Stage></Stage>
                <SliderIcons items={this.props.properties}></SliderIcons>
                <a href="/brochure/Brochure_LomasDeCarabayllo.pdf" className="content-brochure" download>
                    <div className="icon-brochure"><img src={require("../../Assets/images/iconos/carpeta.svg").default}></img></div>
                    <span className="title">Descargar brochure</span>
                </a>
                {/*  */}
                <div className='content-gallery'>
                    <img className="cloud-gallery-1" src={require("../../Assets/images/iconos/cloud.png")}></img>
                    <img className="cloud-gallery-2" src={require("../../Assets/images/iconos/cloud.png")}></img>
                    <section className="Galery container mx-auto ">
                        <h2 className="text-center title semibold">
                            <img className="title-left" src={require("../../Assets/images/iconos/title-left.png")}></img>
                            <img className="title-right" src={require("../../Assets/images/iconos/title-right.png")}></img>
                            <img className="start-1" src={require("../../Assets/images/iconos/starts-1.png")}></img>
                            EMPIEZA A CREAR MÁS MOMENTOS EN FAMILIA
                        </h2>
                        <MediaQuery query="(min-width:740px)">
                        <div className="container-galery">
                            <div className="content-items">
                                <div className="item cont-gallery tipologia active" 
                                onClick={this.changeGallery.bind(this,"tipologia")}
                                >TIPOLOGÍAS</div>
                                <div className="item cont-gallery proyecto" 
                                onClick={this.changeGallery.bind(this,"proyecto")}
                                >PROYECTO</div>
                                <div className="item cont-gallery ubicacion" 
                                onClick={this.changeGallery.bind(this,"ubicacion")}
                                >UBICACIÓN</div>
                                <div className="item cont-gallery comun" 
                                onClick={this.changeGallery.bind(this,"comun")}
                                >ÁREAS COMUNES</div>
                                <div className="item cont-gallery interior" 
                                onClick={this.changeGallery.bind(this,"interior")}
                                >INTERIORES</div>
                            </div>
                            <div className="content-multimedia">
                                
                                <div className="cont-gallery tipologia active">
                                    <OwlCarousel ref="car" options={options} events={events} className="MyCarouselHorizontal">
                                    {
                                            this.props.properties.tipologia.map((e,index)=>{
                                                return(
                                                    <div className="diap" key={"item-tipologia"+index} data-src={e.imgDesktop} href={e.imgDesktop} data-exthumbimage={e.imgDesktop}  data-sub-html={"#interior-"+index}>
                                                        <div className="lupa">
                                                            <div className="icon-lupa"></div>
                                                        </div>
                                                        <img src={e.imgDesktop}></img>
                                                        {/* <div id={"interior"+index}>
                                                            <h4>{e.title}</h4>
                                                        </div> */}
                                                    </div>
                                                )
                                            })
                                        }
                                    </OwlCarousel>
                                </div>
                                <div className="cont-gallery proyecto">
                                    <OwlCarousel ref="car" options={options} events={events} className="MyCarouselHorizontal">
                                        {
                                            this.props.properties.sitio.map((e,index)=>{
                                                return(
                                                    <div className="diap" key={"sitio"+index} data-src={e.img} href={e.img} data-exthumbimage={e.img}  data-sub-html={"#site-"+index}>
                                                        <div className="lupa">
                                                            <div className="icon-lupa"></div>
                                                        </div>
                                                        <img src={e.img}></img>
                                                        {/* <div id={"comun"+index}>
                                                            <h4>{e.title}</h4>
                                                        </div> */}
                                                    </div>
                                                )
                                            })
                                        }
                                    </OwlCarousel>
                
                                </div>
                                <div className="cont-gallery ubicacion">
                                    <div className="diap" data-src={this.props.properties.ubicacion[0].img} href={this.props.properties.ubicacion[0].img} data-exthumbimage={this.props.properties.ubicacion[0].img}  data-sub-html={"#ubicacion-1"}>
                                        <div className="lupa">
                                            <div className="icon-lupa"></div>
                                        </div>
                                        <img src={this.props.properties.ubicacion[0].img}></img>
                                        {/* <div id={"comun"+index}>
                                            <h4>{e.title}</h4>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="cont-gallery comun">
                                    <OwlCarousel ref="car" options={options} events={events} className="MyCarouselHorizontal">
                                        {
                                            this.props.properties.comun.map((e,index)=>{
                                                return(
                                                    <div className="diap" key={"slider-comun"+index} data-src={e.imgDesktop} href={e.imgDesktop} data-exthumbimage={e.imgDesktop}  data-sub-html={"#comun-"+index}>
                                                        <div className="lupa">
                                                            <div className="icon-lupa"></div>
                                                        </div>
                                                        <img src={e.imgDesktop}></img>
                                                        {/* <div id={"comun"+index}>
                                                            <h4>{e.title}</h4>
                                                        </div> */}
                                                    </div>
                                                )
                                            })
                                        }
                                    </OwlCarousel>
                                    
                                </div>
                                <div className="cont-gallery interior">
                                    <OwlCarousel ref="car" options={options} events={events} className="MyCarouselHorizontal">
                                        {
                                            this.props.properties.interior.map((e,index)=>{
                                                return(
                                                    <div className="diap" key={"slider-interior"+index} data-src={e.imgDesktop} href={e.imgDesktop} data-exthumbimage={e.imgDesktop}  data-sub-html={"#interior-"+index}>
                                                        <div className="lupa">
                                                            <div className="icon-lupa"></div>
                                                        </div>
                                                        <img src={e.img}></img>
                                                        {/* <div id={"interior"+index}>
                                                            <h4>{e.title}</h4>
                                                        </div> */}
                                                    </div>
                                                )
                                            })
                                        }
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                        </MediaQuery>
                        <MediaQuery query="(max-width:740px)">
                            <AcordeonInicio sliders={this.props.properties}></AcordeonInicio>
                        </MediaQuery>
                    </section>
                </div>
                <Aliados/>
                <section className="calcula-cuotas">
                    <img className="cloud-left" src={require("../../Assets/images/iconos/cloud.png")}></img>
                    <img className="star-form" src={require("../../Assets/images/iconos/star-form.png")}></img>
                    <img className="cloud-right" src={require("../../Assets/images/iconos/cloud.png")}></img>
                    <img className="point-left" src={require("../../Assets/images/iconos/point-left.png")}></img>
                    
                    <h2 className="text-center title semibold">
                        <img className="title-left" src={require("../../Assets/images/iconos/title-left.png")}></img>
                        <img className="title-right" src={require("../../Assets/images/iconos/title-right.png")}></img>

                        CALCULA TUS CUOTAS MENSUALES
                    </h2>
                    <Calculadora></Calculadora>
                    <img className="background-4 desktop" src={require("../../Assets/images/iconos/background-bottom-calculator.png")}></img>
                    <img className="background-4 movil" src={require("../../Assets/images/iconos/background-bottom-calculator-movil.png")}></img>
                    <img className="b-alcancia " src={require("../../Assets/images/iconos/pig.png")}></img>
                    <img className="b-calculadora " src={require("../../Assets/images/iconos/calculator.png")}></img>
                </section>                                    
                
            </div>
        )
    }
}
